import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, } from 'react-accessible-accordion';

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

import HeroPhoto from '../components/sections/hero-photo'
import SectionHeader from '../components/common/section-header'
import ContactForm from '../components/sections/contact-form'

import '../styles/pages/page-facebookads.scss'
import { facebookads } from '../utils/schema/services'
import { faqFacebookAds } from '../utils/schema/faq'

const pageFacebookads = ({ location }) => {
  const data = useStaticQuery(graphql`{
    ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-facebookads"}}}) {
      ...GhostPageFields
    }
    hero: file(relativePath: {eq: "hero/hero-facebookads.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    likes: file(relativePath: {eq: "facebookads/likes.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    woman: file(relativePath: {eq: "facebookads/woman.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    fb: file(relativePath: {eq: "facebookads/fb.png"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    koino: file(relativePath: {eq: "facebookads/koino.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    kostos: file(relativePath: {eq: "facebookads/kostos.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    stoxeusi1: file(relativePath: {eq: "facebookads/stoxeusi1.png"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    stoxeusi2: file(relativePath: {eq: "facebookads/stoxeusi2.png"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    eukolia: file(relativePath: {eq: "facebookads/eukolia.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    loyalty: file(relativePath: {eq: "facebookads/loyalty.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    saita: file(relativePath: {eq: "facebookads/saita.png"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    saita_iconx2: file(relativePath: {eq: "facebookads/saita_iconx2.png"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    bin: file(relativePath: {eq: "facebookads/kyalia.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
  }
  `)
  const likes = getImage(data.likes)
  const bgLikes = convertToBgImage(likes)
  const saita = getImage(data.saita)
  const bgSaita = convertToBgImage(saita)
	let page = data.ghostPage

  return <>
    <MetaData
      data={data}
      location={location}
      type="website"
    />
    <Helmet>
      <style type="text/css">{`${page.codeinjection_styles}`}</style>
      <script type="application/ld+json">{JSON.stringify(facebookads)}</script>
      <script type="application/ld+json">{JSON.stringify(faqFacebookAds)}</script>
    </Helmet>
    <Layout viewport={''}>
      <article className="page-facebookads">
        <HeroPhoto
          headerTitle={"Facebook Ads - Facebook Marketing"}
          ctaImage={data.hero.childImageSharp.gatsbyImageData}
        />
        <section className="mt-5">
          <BackgroundImage {...bgLikes} className="facebookads__marketing container" style={{backgroundPosition: "85% 95%", backgroundSize: "60% auto"}}>
            <div className="container medium">
              <h2 className="dec-header--underline">Υπηρεσίες Facebook Marketing με αποτέλεσμα!</h2>
              <strong className="my-5">Στη Think Plus χρησιμοποιούμε στρατηγικά το Facebook, ως ένα εξαιρετικό εργαλείο για διαφημιστικές καμπάνιες που δημιουργούν επισκεψιμότητα και φέρνουν πωλήσεις.</strong>
              <p>Μας αρέσουν τα πρωτόκολλα και οι διαδικασίες. Ξεκινάμε την όλη διαδικασία δημιουργώντας μία στρατηγικά δομημένη και πολυεπίπεδη καμπάνια. μέσα από όλες τις διαφημιστικές υπηρεσίες του Facebook. Aυτή η διαδικασία περιλαμβάνει την ενδυνάμωση της αναγνωρισιμότητας του σήματος, την εκδήλωση ενδιαφέροντος για την εταιρεία ή τις υπηρεσίες της, τη δημιουργία ανάγκης και πρόθεσης αγοράς και τελικά την ίδια τη πράξη της συναλλαγής. Φυσικά είμαστε στην ευχάριστη θέση να υποστηριζόμαστε από μερικούς από τους καλύτερους ειδικούς στη διαχείριση διαφημίσεων στο Facebook που χρησιμοποιούν λεπτομερείς επιλογές στόχευσης και πραγματοποιούν σε βάθος ανάλυση για τη δημιουργία διαφημιστικών καμπανιών που βασίζονται σε απόδοση επένδυσης (ROI).</p>
            </div>
          </BackgroundImage>
        </section>
        <section className="mt-5">
          <div className="container medium">
            <h2 className="h3 dec-header--underline d-inline-block mb-4">Γιατί διαφήμιση στο Facebook;</h2>
            <div className="row">
              <div className="col-md-6">
                <GatsbyImage
                  image={data.woman.childImageSharp.gatsbyImageData}
                  className="mb-4 mb-md-0"
                  alt="" />
              </div>
              <div className="col-md-6">
                <p>Το facebook δεν είχε πάντα διαφημίσεις, τα γνωστά, σ’ όλους μας σήμερα facebook ads. Μάλιστα τα πρώτα χρόνια ο ιδρυτής του, με πάθος υποστήριζε ότι δεν θα βάλει ποτέ facebook ads, καθώς ήθελε να κρατήσει το περιεχόμενο ενδιαφέρον, διαδραστικό και αληθινό για τους χρήστες. Όλα αυτά φυσικά μέχρι το 2014 που η εταιρεία εισήχθει στο Αμερικάνικο χρηματιστήριο και από τότε τα facebook ads αποτελούν την αιχμή του δόρατος των εσόδων του παγκόσμιου κολοσσού.<br />Σήμερα, η ολοκληρωμένη και συνεπής παρουσία των brands στο Facebook είναι εξαιρετικά σημαντική καθώς το μέσο αποτελεί ένα άριστο κανάλι επικοινωνίας. Φυσικά το καλό περιεχόμενο δεν αρκεί αν δεν συνοδεύεται από facebook ads.</p>
                <strong className="d-inline-block mb-2">Η αύξηση της δημοφιλίας και των πωλήσεων μέσω του Facebook προϋποθέτει μία ολιστική στρατηγική επικοινωνίας, ένα business Facebook page, ικανό budget για απόλυτα στοχευμένη διαφήμιση μέσω facebook ads και φυσικά άριστο περιεχόμενο. Μ’ όλα τα παραπάνω το Facebook μπορεί να φέρει μεγάλη αξία στην επιχείρηση ή το brand σας.</strong>
                <p>Αριθμώντας μία τεράστια κοινότητα χρηστών, το facebook ads αποτελεί την άριστη πρακτική διαφήμισης μέσα από τα κοινωνικά δίκτυα. Παρόλο που θυμίζει τη διαφήμιση στη google τα γνωστά σ’ όλους μας google adwords, οι διαφορές των facebook ads είναι πάρα πολλές. Η πλέον βασική είναι ότι αντίθετα με την πληρωμένη αναζήτηση, που εξυπηρετεί τις επιχειρήσεις να βρουν καινούργιους εν δυνάμει πελάτες μέσω λέξεων-κλειδιών ή φράσεων-κλειδιών, τα facebook ads βοηθούν τους χρήστες να βρουν επιχειρήσεις βασιζόμενοι σε πράγματα που τους ενδιαφέρουν ή έχουν να κάνουν με δικά τους χόμπυ ή θέλω, έχοντας καταγράψει το συμπεριφορικό τους μοντέλο. Εν κατακλείδι η google σας βοηθά να βρείτε νέους πελάτες, ενώ τα facebook ads βοηθούν τους νέους πελάτες να σας βρουν.</p>
              </div>
            </div>
            <p className="mt-4">Όπως συμβαίνει και με κάθε άλλο διαφημιστικό μέσο και κανάλι επικοινωνίας, έτσι και τα facebook ads, έχουν σημαντικά πλεονεκτήματα , αλλά και μειονεκτήματα, τα οποία πρέπει να συνυπολογιστούν σοβαρά προκειμένου να δημιουργηθεί μία εύστοχη και αποδοτική διαφήμιση.</p>
          </div>
        </section>
        <section className="mt-5">
          <div className="container medium">
            <h2 className="h3">Πλεονεκτήματα Facebook Ads:</h2>
            <p className="m-0">Οι διαφημίσεις Facebook, τα Facebook ads, έχουν τη δυνατότητα να προσεγγίσουν ένα διευρυμένο κοινό χρηστών του Διαδικτύου, καθώς είναι μακράν ο πιο δημοφιλής ιστότοπος στον κόσμο.</p>
            <strong className="d-inline-block mb-4">Σχεδόν το 80% των χρηστών του Διαδικτύου χρησιμοποιούν το Facebook είτε για προσωπική τους χρήση είτε ως διαφημιστική πλατφόρμα.</strong>
            <p>Με όλους αυτούς τους χρήστες, η στόχευση του Facebook για την έναρξη μιας διαφημιστικής καμπάνιας έχει απλά το τέλειο νόημα.<br /> Παρέχει το ίδιο το κοινωνικό μέσο ένα κανάλι για να προσεγγίσει ο διαφημιζόμενος οποιοδήποτε κοινό-στόχο,<br /> ανάλογα με το πώς θέλει να το χρησιμοποιήσει.</p>
          </div>
          <div className="background__theme">
            <div className="container medium">
              <div className="row">
                <div className="col-md-6">
                  <p className="py-5">Αξιοποιώντας τις δυνατότητες που προσφέρουν οι διαφημίσεις Facebook, μπορείτε να καθορίσετε ποιος βλέπει τις διαφημίσεις σας ανά τοποθεσία, ηλικιακή ομάδα, αγοραστικές συνήθειες και πολλά άλλα. Επιπλέον, είναι μια από τις φθηνότερες μορφές διαφήμισης, επιτρέποντας στις διαφημίσεις σας να προσεγγίζουν χιλιάδες ανθρώπους για λιγότερα χρήματα από οποιοδήποτε άλλο μέσο. Ο παράγοντας κόστους είναι εξαιρετικά επιδραστικός στο γιατί είναι πολύ λογικό να χρησιμοποιείτε το Facebook για μια διαφημιστική καμπάνια.</p>
                </div>
                <div className="col-md-6 facebookads__advantages">
                  <GatsbyImage
                    image={data.fb.childImageSharp.gatsbyImageData}
                    alt="Facebook"
                    style={{position: "absolute"}} />
                </div>
              </div>
            </div>
          </div>
          <div className="container medium mt-5">
            <div className="row">
              <div className="col-md-6 pr-md-5 mb-4 mb-md-0">
                <h3 className="h4 color--primary">Ευρύ κοινό</h3>
                <p className="m-0">Τις περισσότερες επιχειρήσεις, νεοσύστατες και μη, τις απασχολεί η αύξηση του πελατολογίου τους. Τα facebook ads σας δίνουν λοιπόν πρόσβαση σε σχεδόν 2 δις ανθρώπους και ενεργούς χρήστες, ανά την υφήλιο.</p>
                <GatsbyImage image={data.koino.childImageSharp.gatsbyImageData} alt="" />
              </div>
              <div className="col-md-6 pl-md-5">
                <h3 className="h4 color--primary">Χαμηλό αρχικό κόστος</h3>
                <GatsbyImage image={data.kostos.childImageSharp.gatsbyImageData} alt="" />
                <p className="mt-3">Σε πλήρη αντίθεση με τις παραδοσιακές μορφές διαφήμισης, όπως η διαφήμιση στη τηλεόραση, το ραδιόφωνο και τα έντυπα μέσα τα οποία απαιτούν σημαντικό αρχικό προϋπολογισμό, τον οποίο αρκετές νέες επιχειρήσεις δεν μπορούν να διαθέσουν, τα facebook ads ξεκινούν με ελάχιστο ημερήσιο budget το 1 ευρώ.</p>
              </div>
            </div>
            <h3 className="h4 color--primary mt-5">Επιλογές στόχευσης</h3>
            <div className="row align-items-center">
              <div className="col-md-4">
                <GatsbyImage image={data.stoxeusi1.childImageSharp.gatsbyImageData} alt="Στόχευση fb" />
              </div>
              <div className="col-md-4">
                <p className="mt-4 mt-md-0">Είναι βέβαιο ότι επιθυμείτε να απευθύνετε το μήνυμα σας σε συγκεκριμένο κοινό και μόνο γι αυτό επιθυμείτε να πληρώσετε. Και πάλι σε πλήρη αντίθεση με τις παραδοσιακές μορφές διαφήμισης, όπως η τηλεόραση, το ραδιόφωνο και τα έντυπα μέσα τα οποία δεν δίνουν καμία δυνατότητα ουσιαστικής και τεκμηριωμένης στόχευσης, τα facebook ads διαθέτουν μία τεράστια ποικιλία από επιλογές στόχευσης.</p>
                <strong className="mb-4 mt-md-0">Ηλικία, θέση στην εταιρεία, χώρα, περιοχή, χόμπυ και πολλά άλλα είναι μόνο μερικές από τις επιλογές που προσφέρουν τα facebook ads.</strong>
              </div>
              <div className="col-md-4">
                <GatsbyImage image={data.stoxeusi2.childImageSharp.gatsbyImageData} alt="Στόχευση fb" />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6 pr-md-5 ">
                <h3 className="h4 color--primary">Customer Loyalty</h3>
                <GatsbyImage image={data.loyalty.childImageSharp.gatsbyImageData} alt="" />
                <p className="mt-3">Η συνεπής, άρτια και εύστοχη διαχείρηση της σελίδας σας στο facebook, βοηθά το κοινό σας να παραμένει συνδεδεμένο με την επιχείρηση σας. Έτσι είστε σε θέση να χτίζετε ουσιαστικές σχέσεις και να μετατρέπετε τους πελάτες σας από ευκαιριακούς σε πιστούς και αφοσιωμένους πελάτες.</p>
              </div>
              <div className="col-md-6 pl-md-5 ">
                <h3 className="h4 color--primary">Ευκολία στη χρήση</h3>
                <p className="mb-4">Ένα από τα πλέον σημαντικά επιχειρήματα της διαφήμισης στο Facebook, μέσα από τα facebook ads είναι ότι δεν χρειάζεται να είναι κανείς επαγγελματίας διαφημιστής κανείς, προκειμένου να δημιουργήσει μία διαφημιστική καμπάνια για την επιχείρησή του.</p>
                <p className="mb-4">Φυσικά αν θέλει να εκμεταλλευθεί απόλυτα τις δυνατότητες που δίνουν τα facebook ads και να δει σημαντική αύξηση στη κερδοφορία του από το δαπανόμενο διαφημιστικό κονδύλι, καλό θα ήταν να απευθυνθεί σε μία διαφημιστική.</p>
                <GatsbyImage image={data.eukolia.childImageSharp.gatsbyImageData} alt="" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container medium">
            <BackgroundImage {...bgSaita} className="facebookads__advantages-title" style={{backgroundPosition: "right bottom", backgroundSize: "29% auto"}}>
              <h2 className="h3">Μερικά ακόμη πλεονεκτήματα των διαφημίσεων στο Facebook.</h2>
            </BackgroundImage>
            <div className="facebookads__advantages-more">
              <GatsbyImage image={data.saita_iconx2.childImageSharp.gatsbyImageData} alt="" />
              <p>Υπάρχουν πολλές άλλες πτυχές των διαφημίσεων στο Facebook που κάνουν τα Facebook ads μια ελκυστική πλατφόρμα για την εκκίνηση μιας διαφημιστικής καμπάνιας. Με τη στόχευση των διαφημίσεών σας με απόλυτη ακρίβεια, σχεδόν αμέσως αυξάνετε την επισκεψιμότητα στον ιστότοπό σας. Αυτό αυξάνει την κατάταξή σας και στη Google και βελτιώνει σημαντικά τη θέση του ιστότοπού σας, φέρνοντας τον πιο κοντά στην κορυφή των αποτελεσμάτων αναζήτησης.</p>
            </div>
            <div className="facebookads__advantages-more">
              <GatsbyImage image={data.saita_iconx2.childImageSharp.gatsbyImageData} alt="" />
              <p>Οι διαφημίσεις στα Facebook ads είναι επίσης μετρήσιμες. Θα μπορείτε εύκολα να δείτε τα αποτελέσματα της καμπάνιας σας εξετάζοντας τον αριθμό των κλικ και των μετατροπών που λαμβάνετε. Έτσι μπορείτε να επιλέξετε τη στόχευσή σας για να αξιοποιήσετε στο έπακρο την καμπάνια σας.</p>
            </div>
            <div className="facebookads__advantages-more">
              <GatsbyImage image={data.saita_iconx2.childImageSharp.gatsbyImageData} alt="" />
              <p>Αναφέραμε πόσο φθηνή είναι η διαφήμιση στο Facebook; Οι διαφημίσεις στο Facebook, εάν γίνουν σωστά, μπορούν να μειώσουν σημαντικά το κόστος πρόσκτησης ενός νέου πελάτη. Μπορείτε να ξοδέψετε πολύ λιγότερα για να κερδίσετε μια μετατροπή από ό,τι θα ξοδεύατε σε άλλες μορφές διαφήμισης στο διαδίκτυο.</p>
            </div>
            <div className="facebookads__advantages-more">
              <GatsbyImage image={data.saita_iconx2.childImageSharp.gatsbyImageData} alt="" />
              <p>Οι διαφημίσεις στο Facebook μπορούν επίσης να οδηγήσουν επαναλαμβανόμενες πωλήσεις. Το Facebook διαθέτει μια λειτουργία «Κοινό» που σας επιτρέπει να εισάγετε email και διευθύνσεις πελατών στη διαφημιστική σας καμπάνια στο Facebook. Αυτό κάνει τα χρήματα που ξοδεύετε σε κάθε διαφήμιση να προχωρούν πολύ περισσότερο με τη στόχευση ατόμων που είναι πιθανότερο να ξαναγοράσουν λόγω της τοποθέτησης μιας διαφήμισης.</p>
            </div>
            <div className="facebookads__advantages-more">
              <GatsbyImage image={data.saita_iconx2.childImageSharp.gatsbyImageData} alt="" />
              <p>Το Facebook είναι μια κοινωνική πλατφόρμα. Αυτό σημαίνει ότι οι στοχοθετημένοι χρήστες συνεργάζονται με άλλους χρήστες σε τακτική βάση. Αυτό βοηθά στη διάδοση των πληροφοριών από ικανοποιημένους πελάτες των προϊόντων και των υπηρεσιών σας. Η κοινωνική πλευρά του Facebook είναι κυρίως γιατί η πλατφόρμα έχει γίνει τόσο αποτελεσματική στη διαφήμιση.</p>
            </div>
            <div className="facebookads__advantages-more">
              <GatsbyImage image={data.saita_iconx2.childImageSharp.gatsbyImageData} alt="" />
              <p>Οι διαφημίσεις στο Facebook πραγματοποιούνται εδώ και τώρα. Αυτή η πτυχή, δηλαδή η άμεση παραμετροποιήση σε πραγματικό χρόνο των διαφημίσεων Facebook σημαίνει ότι έχετε τη δυνατότητα να προσαρμόσετε άμεσα τους στοχευμένους χρήστες, εάν δεν έχετε λάβει τα αποτελέσματα που περιμένετε.<br />Πηγαίνοντας απευθείας στους χρήστες που πιθανότατα θα χρησιμοποιήσουν τα προϊόντα και τις υπηρεσίες σας, μπορείτε να αποκτήσετε ένα συγκριτικό πλεονέκτημα από τον ανταγωνισμό σας που χρησιμοποιούν πιο συμβατικές πηγές διαδικτυακής διαφήμισης όπως τα Google Αds. Εφόσον γνωρίζετε ακριβώς ποιος είναι πιθανόν να γίνει πελάτης σας, το Facebook παρέχει ένα shortcut στο κοινό-στόχο σας.</p>
            </div>
          </div>
          <div className="background__theme my-5 py-5">
            <div className="container medium">
              <p>Aν τελικά το ερώτημα που υπάρχει στο μυαλό σας όλη αυτή την ώρα που διαβάζετε το συγκεκριμένο άρθρο είναι :</p>
              <p className="h5 my-5" style={{fontSize: "calc(.8rem + .4vw)"}}>“Υπάρχει μια εταιρεία που μπορεί να δημιουργήσει και να εκτελέσει μια διαφημιστική καμπάνια στο Facebook για μένα;”</p>
              <p className="h5 color--primary">Φυσικά και υπάρχει. Είστε στο σωστό μέρος..!</p>
              <p>Η Think Plus είναι μια καταξιωμένη και βραβευμένη εταιρεία ψηφιακού μάρκετινγκ. Με διακρίσεις σε Ελλάδα και εξωτερικό, η Think Plus έχει αποδεδειγμένα την ικανότητα και εμπειρία να διεξάγει μια διαφημιστική καμπάνια στο Facebook που μπορεί να προσφέρει αποτελέσματα πολύ πέρα από τις προσδοκίες σας. Οι ειδικοί μας μπορούν να φροντίσουν τις καθημερινές παραμετροποιήσεις και να βελτιώσουν το μήνυμα που εκπέμπετε και την προσέγγιση που ακολουθείτε για να προσεγγίσετε υποψήφιους πελάτες.</p>
              <p className="m-0">Αυτή η ικανότητα κατανόησης του τι λειτουργεί και τι όχι στο ψηφιακό μάρκετινγκ είναι ένας από τους λόγους για τους οποίους η Think Plus έχει γίνει εκ των κορυφαίων ελληνικών εταιρειών ψηφιακού μάρκετινγκ.</p>
              <strong>Επικοινωνήστε μαζί μας αν σας ενδιαφέρει να αξιοποιήσετε τη σημαντική διαφημιστική δύναμη που αντιπροσωπεύει το Facebook.</strong>
            </div>
          </div>
        </section>
        <section className="pb-5">
          <div className="container medium">
            <h2 className="h5" style={{fontSize: "calc(.7rem + .4vw)"}}>Δείτε μερικά δείγματα της εμπειρίας μας στα Facebook Ads παρακάτω:</h2>
            <div className="row no-gutters">
              <div className="col-md-7">
                <GatsbyImage
                  image={data.bin.childImageSharp.gatsbyImageData}
                  alt=""
                  style={{height: "100%"}} />
              </div>
              <div className="col-md-5 d-grid">
                <div  className="p-3" style={{backgroundColor: "#f6f7fb"}}>
                  <h3 className="h5 mb-1">Λεπτομερής στόχευση.</h3>
                  <p className="m-0">Εκτός από τη δημιουργία λεκτικών διαφήμισης που εστιάζουν στη πώληση, χρησιμοποιούμε καλύτερα τη λειτουργία "Λεπτομερής στόχευση" της διαφήμισης στο Facebook για να μεγιστοποιήσουμε την προσέγγιση στο πιο σχετικό κοινό.</p>
                </div>
                <div  className="p-3" style={{backgroundColor: "#eceff6"}}>
                  <h3 className="h5 mb-1">Δημιουργία προσαρμοσμένου κοινού.</h3>
                  <p className="m-0">Οι ειδικοί μας στο Facebook δημιουργούν ιδιαίτερα στρατηγικές διαφημιστικές καμπάνιες με γνώμονα το ROI, δημιουργώντας ένα προσαρμοσμένο κοινό. Αυτό περιλαμβάνει τα άτομα που έχουν δείξει ενδιαφέρον για την επιχείρησή σας ή μια προσαρμοσμένη λίστα χρηστών που έχουν το σχετικό ενδιαφέρον.</p>
                </div>
                <div  className="p-3" style={{backgroundColor: "#dbdfeb"}}>
                  <h3 className="h5 mb-1">Ακριβώς δομημένη αναφορά.</h3>
                  <p className="m-0">Εμείς ως εταιρεία ψηφιακού μάρκετινγκ στο Facebook ακολουθούμε μια καλά καθορισμένη διαδικασία αναφοράς. Σας ενημερώνουμε σε κάθε βήμα σχετικά με την απόδοση της καμπάνιας σας, ενώ εφαρμόζουμε μια ακριβώς δομημένη και σαφή μορφή αναφοράς. Με λίγα λόγια δεν κάνουμε τίποτα που δεν φέρνει αποτέλεσμα και σε καμία περίπτωση αν δεν το έχετε εγκρίνει πρώτα.</p>
                </div>
              </div>
            </div>
            <div  className="p-3" style={{backgroundColor: "#c8cfe1"}}>
              <h3 className="h5 mb-1">Δυναμικές διαφημίσεις προϊόντων.</h3>
              <p className="m-0">Δημιουργούμε στρατηγικές παραγωγικών διαφημιστικών καμπανιών στο Facebook χρησιμοποιώντας δυναμικές καμπάνιες διαφημίσεων προϊόντων που βοηθούν στην αυτόματη προώθηση προϊόντων στο κοινό που είχε προηγουμένως δείξει ενδιαφέρον για αυτά τα προϊόντα. Το πρότυπο διαφήμισης που δημιουργείται μπορεί να δημιουργεί αυτόματα εικόνες από τα δεδομένα του προϊόντος.</p>
            </div>
            <div  className="p-3" style={{backgroundColor: "#b5bfd8"}}>
              <h3 className="h5 mb-1">Δημιουργία διοχέτευσης για περισσότερες μετατροπές.</h3>
              <p className="m-0">Από τη δημιουργία καμπανιών αναγνωρισιμότητας του brand, την οικοδόμηση καμπανιών για την εκδήλωσης ενδιαφέροντος ως προς τη πρόθεση αγοράς μέχρι και την τελική πώληση του προϊόντος ή της υπηρεσίας σας, σχεδιάζουμε ολόκληρη τη καμπάνια πολυεπίπεδα και πολυσυλλεκτικά.</p>
            </div>
          </div>
        </section>
        <section>
          <div className="background__theme--dark my-5">
            <SectionHeader
              title="Facebook Ads"
              text="FAQ"
            />
          </div>
          <div className="container medium">
            <div className="row">
              <div className="col-md-6">
                <Accordion
                  className="accordion--theme"
                  allowZeroExpanded={true}
                >
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Πώς λειτουργούν οι διαφημίσεις στο Facebook;</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Υπάρχουν διάφοροι τρόποι για να διαμορφώσετε μια διαφήμιση στο Facebook για να σας δώσει τα αποτελέσματα που επιθυμείτε.</p>
                      <p>Τα Facebook ads σας επιτρέπουν να στοχεύσετε το κοινό σας ανά φύλο, ηλικία, τοποθεσία, αγοραστικές συνήθειες, ενδιαφέροντα και πολλά άλλα κριτήρια. Αφού περιορίσετε τον τύπο των ατόμων που στοχεύετε, η διαφήμισή σας θα εμφανίζεται μόνο στην πλαϊνή γραμμή, στο messenger, στο audience network ή στο newsfeed αυτών των καθορισμένων χρηστών.</p>
                      <p>Οι διαφημίσεις μπορούν να μιλούν για τα προϊόντα και τις υπηρεσίες σας ή μπορούν απλά να κατευθύνουν πιθανούς αγοραστές στον δικό σας ιστότοπο, αυξάνοντας την επισκεψιμότητα.</p>
                      <p>Για παράδειγμα, μπορείτε να στοχεύσετε μέσα από το διαχειριστικό πίνακα των facebook ads η διαφήμισή σας να εμφανίζεται μόνο στις σελίδες ατόμων ηλικίας 35-40 ετών που ζουν σε συγκεκριμένο ταχυδρομικό κώδικα. </p>
                      <p>Αυτή η δυνατότητα στόχευσης της διαφήμισής σας, καθιστά απίστευτα εύκολο να περιορίσετε την εστίασή σας μόνο σε εκείνους τους ανθρώπους που είναι πιθανό να αγοράσουν το προϊόν ή την υπηρεσία σας, περιορίζοντας σημαντικά το budget σας.</p>
                      <p>Αφού επιλέξετε το target audience σας, στη συνέχεια ορίζετε έναν προϋπολογισμό και κοστολογείστε είτε για κάθε κλικ που λαμβάνει η διαφήμισή σας, είτε, εάν στοχεύετε σε μια μεγάλη ομάδα, κάθε χίλια κλικ που λαμβάνει η διαφήμισή σας.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Πώς μπορώ να δημιουργήσω μια επιτυχημένη διαφήμιση Facebook;</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Το κλειδί για μια επιτυχημένη διαφημιστική καμπάνια στο Facebook είναι να ακολουθήσουμε μια προσέγγιση «soft-selling».</p>
                      <p>Σκεφτείτε το από την οπτική γωνία του χρήστη. Δεν μπήκαν σήμερα στο Facebook γνωρίζοντας ότι η διαφήμισή σας θα εμφανιστεί στη σελίδα τους, επομένως δεν είναι πιθανό να αγοράσουν σήμερα τα προϊόντα και τις υπηρεσίες σας, παρόλο που η διαφήμιση σας τους στοχεύει ως δυνητικό πελάτη. Μπορεί όμως παρόλα αυτά να ενδιαφέρονται αρκετά και να προχωρήσουν ακόμη και σε αγορές.<br />Η landing page στο website σας στην οποία κατευθύνονται αφού κάνουν κλικ στη διαφήμισή σας θα πρέπει να ακολουθεί την ίδια προσέγγιση “soft selling” και να εμφανίζει μόνο πληροφορίες που νομίζετε ότι θα τους ενδιέφεραν.</p>
                      <p>Δεν τους ζητάτε κάτι συγκεκριμένο αυτή τη στιγμή. Εσείς το μόνο που πρέπει να κάνετε είναι να φυτέψετε τους σπόρους ενδιαφέροντος.</p>
                      <p>Πηγαίνοντας απευθείας στην προσέγγιση "hard-sell" μόλις φτάσουν στον ιστότοπό σας, θα χάσετε το ενδιαφέρον που έχετε κερδίσει από την τοποθέτηση της διαφήμισής σας.</p>
                      <p>Κατανοήστε ότι όλες οι διαφημιστικές καμπάνιες είναι μια επένδυση.<br />Εάν δεν αποδώσουν αμέσως, αυτό δεν σημαίνει ότι έχουν αποτύχει. Πρέπει να αναγνωρίσετε ότι, προσελκύοντας έναν χρήστη που κάνει κλικ στη διαφήμισή σας, έχετε αποκτήσει το ενδιαφέρον ενός υποψήφιου πελάτη που μπορεί να αγοράσει τα προϊόντα και τις υπηρεσίες σας, στο εγγύς μέλλον.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Χρειάζομαι έναν ιστότοπο για την εκτέλεση διαφημίσεων στο Facebook;</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Όχι, όχι!<br />Ενώ οι περισσότερες καμπάνιες απαιτούν διεύθυνση URL, υπάρχουν μερικές επιλογές για όσους δεν διαθέτουν ακόμη ιστότοπο. Ωστόσο, σε όλες τις καμπάνιες απαιτείται σελίδα Facebook.</p>
                      <p>Αφού ρυθμίσετε τη σελίδα σας στο Facebook, θα χρειαστεί να δημιουργήσετε ένα fanbase. Μπορείτε να εκτελέσετε μια καμπάνια "Like", για να αυξήσετε τους θαυμαστές της σελίδας και να αυξήσετε την προσέγγιση χρηστών.</p>
                      <p>Μετά τη δημιουργία και επιμέλεια του τέλειου ελκυστικού περιεχομένου για τους θαυμαστές της σελίδας σας, μπορείτε να ενισχύσετε τις αναρτήσεις της σελίδας σας για να αυξήσετε την προβολή κάθε μιας από τις αναρτήσεις σας.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Ποιος είναι ο ελάχιστος προϋπολογισμός που μπορώ να χρησιμοποιήσω για την εκτέλεση διαφημίσεων στο Facebook;</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Αυτό θα εξαρτηθεί από πολλούς παράγοντες, φυσικά: θα πρέπει να λάβετε υπόψη τα δημιουργικά, το κοινό, την υποβολή προσφορών - για να αναφέρουμε μόνο μερικά. Συνήθως προτείνουμε να έχετε τουλάχιστον 2 έως 5 ευρώ ανά ad set.</p>
                      <p>Δεν χρειάζεται να ξεκινήσετε με μεγάλο budget αλλά και μεγάλη προσπάθεια, μια αρχική ρύθμιση θα μπορούσε να είναι 2 εικόνες + 2 διαφημιστικά κείμενα + 2 φύλα. Αυτό θα δημιουργήσει 8 διαφημίσεις και με τον προϋπολογισμό των 20 ευρώ, θα μπορείτε να βλέπετε αξιόπιστα αποτελέσματα και να βελτιώνετε την απόδοση των διαφημίσεων σας.</p>
                      <p>Ενώ η επιλογή των δοκιμών παραμετροποίησης του κοινού εξαρτάται πραγματικά από το είδος της επιχείρησης που διαφημίζετε και τον διαθέσιμο προϋπολογισμό σας. Έτσι μπορείτε να επιλέξετε από μια ευρεία γκάμα επιλογών όπως Χώρα, Φύλο, Τοποθέτηση, Ενδιαφέροντα, Ηλικία, Προσαρμοσμένα είδη κοινού, Συμπεριφορές αγορών, Εκπαίδευση Επίπεδο.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="col-md-6">
                <Accordion
                  className="accordion--theme"
                  allowZeroExpanded={true}
                >
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Σε ποια είδη κοινού μπορώ να στοχεύσω κατά τη δημιουργία των καμπανιών μου στο Facebook;</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Υπάρχουν τρία κύρια είδη κοινού που μπορείτε να χρησιμοποιήσετε για τη στόχευσή σας στο Facebook:</p>
                      <p><strong>Αποθηκευμένα κοινά</strong> - Είδη κοινού που μπορείτε να δημιουργήσετε βάσει τοποθεσιών, ενδιαφερόντων, δημογραφικών στοιχείων και συμπεριφορών.</p>
                      <p><strong>Προσαρμοσμένα κοινά</strong> - Κοινά που αποτελούνται από σχετικές λίστες ηλεκτρονικού ταχυδρομείου, προσαρμοσμένα κοινά που έχετε συλλέξει μέσα από το facebook pixel, ή τέλος κοινά που είχαν συγκεκριμένες αντιδράσεις μέσα από το προφίλ τους σε facebook &amp; instagram.</p>
                      <p><strong>Σχετικά κοινά</strong> - Κοινό που δημιουργήθηκε από το Facebook που μοιάζει με ένα προσαρμοσμένο κοινό της επιλογής σας.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Ποια είναι η διαφορά μεταξύ τoυ promoted post και μιας διαφημιστικής καμπάνιας στο Facebook;</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Και οι δύο τύποι καμπάνιας έχουν τα πλεονεκτήματα και τα μειονεκτήματα που πρέπει να λάβετε υπόψη όταν αποφασίζετε αν θα εκτελέσετε μια καμπάνια για την εξωτερική ιστοσελίδα ή θα ενισχύσετε μια οργανική θέση.</p>
                      <p>Διαφήμιση του Website / E-Shop είναι μακράν ο πιο δημοφιλής τύπος καμπάνιας για μάρκετινγκ απόδοσης.</p>
                      <p>Υποστηρίζει μορφές διαφημίσεων στατικής εικόνας, βίντεο ή καρουσέλ και δίνει τη δυνατότητα στους διαφημιζόμενους να διαχωρίζουν τους τίτλους των δοκιμών, το διαφημιστικό κείμενο, τις εικόνες (ή τα βίντεο!), τις περιγραφές των συνδέσεων, τις διευθύνσεις URL και τα CTA. Ωστόσο, απαιτεί αρκετό προϋπολογισμό για δοκιμές.</p>
                      <p>Τα promoted posts αντίθετα έχουν μεγαλύτερη ορατότητα καθώς εμφανίζονται στη σελίδα σας στο Facebook που σημαίνει ότι όλες οι επισημάνσεις "μου αρέσει", τα σχόλια και οι κοινοποιήσεις είναι σε ένα μέρος και το social proof τείνει να είναι υψηλότερο Ωστόσο, δεν έχετε την ευκαιρία να εκτελέσετε split testing.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τελικά, facebook promoted post ή facebook ad campaigns;</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Προτείνουμε να ξεκινήσετε με facebook ad campaigns, καθώς η δοκιμή A / B μειώνει σημαντικά το CPC.</p>
                      <p>Στη συνέχεια, όταν το δημιουργικό έχει δοκιμαστεί εκτενώς, μπορείτε να μετατρέψετε τα καλύτερα στοιχεία σε promoted post - αλλά μόνο αν έχετε δοκιμάσει τα πάντα πρώτα!</p>
                      <p>Εν κατακλείδι, το Facebook όπως και άλλα κοινωνικά δίκτυα, είναι ένα εξαιρετικό μέρος για την προώθηση της επιχείρησής σας και του brand σας στα πλαίσια της στρατηγικής επικοινωνίας που έχετε ορίσει.</p>
                      <p>Όπως οποιαδήποτε άλλη πλατφόρμα, έχει πλεονεκτήματα αλλά και μειονεκτήματα. Παρόλα αυτά, το Facebook προσφέρει καλύτερη κατανόηση του engagement των διαφημίσεών σας στο target audience, είναι αρκετά εύκολο στη χρήση και πολύ οικονομικά προσιτό για κάθε επιχείρηση. Το πλεόν βασικό πλεονέκτημα του Facebook είναι ότι έχει εξελιχθεί σ’ ένα “οne stop shop”  για διασκέδαση, επιχειρείν, self branding, επικοινωνία, ανταλλαγή πληροφοριών, διαφήμιση, δικτύωση και πολλά πολλά ακόμη. Είναι αναμφίβολα ένα εργαλείο που έχει μπει δυναμικά στη ζωή μας και πλέον έχει γίνει και αναπόσπαστο κομμάτι της επιχειρηματικής μας δραστηριότητας, του marketing plan &amp; του media mix.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </section>
      </article>
      <ContactForm />
    </Layout>
  </>;
}

export default pageFacebookads
